(function ($) {
    var popupWindow  = {
        linkSelector: "[data-target='popup']",
        heightSelector: "windowHeight",
        widthSelector: "windowWidth",
        init: function() {
            $(popupWindow.linkSelector).click(function(e){
                e.preventDefault();
                PromiseWindow.open($(this).attr('href'), {
                    width: $(this).data(popupWindow.widthSelector),
                    height: $(this).data(popupWindow.heightSelector)
                });
            });
        }
    };

    window.popupWindow = popupWindow;
    $(document).ready(popupWindow.init);
}(jQuery));
